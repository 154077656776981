import React from 'react'
// import { useLocation } from 'react-router-dom'
import '../components/strip-checkout/css/global.css'
import '../components/strip-checkout/css/normalize.css'

const Success = () => {
  // const location = useLocation()
  // const sessionId = location.search.replace('?session_id=', '')

  return (
    <div className="sr-root">
      <div className="sr-main">
        <header className="sr-header">
          <div className="sr-header__logo"></div>
        </header>
        <div className="sr-payment-summary completed-view">
          <h1>Your payment succeeded</h1>
          <h4>Checkout Session ID:</h4>
        </div>
        <div className="sr-section completed-view">
          <div className="sr-callout">
            {/* <pre>{sessionId}</pre> */}
          </div>
          {/* <Link to="/">Restart demo</Link> */}
        </div>
      </div>
      <div className="sr-content">
        <div className="pasha-image-stack">
          <img
            alt=""
            src="https://picsum.photos/280/320?random=1"
            width="140"
            height="160"
          />
          <img
            alt=""
            src="https://picsum.photos/280/320?random=2"
            width="140"
            height="160"
          />
          <img
            alt=""
            src="https://picsum.photos/280/320?random=3"
            width="140"
            height="160"
          />
          <img
            alt=""
            src="https://picsum.photos/280/320?random=4"
            width="140"
            height="160"
          />
        </div>
      </div>
    </div>
  )
}

export default Success
